import Vue from 'vue'
import Vuex from 'vuex'

import Courses from './modules/courses'
import Player from './modules/player'

Vue.use(Vuex)

export default new Vuex.Store({

    /*
        Vuex manages a single state tree – this single object includes all your application-level state
        and serves as the “single source of truth”.
        This also means usually, you will have only one store for each application.
     */
    state: {},

    /*
        Vuex allows us to define “getters” in the store. They work as a computed property for stores.
        The same computed properties, a getter’s outcome is cached based on its dependencies, and will
        solely re-evaluate when some of its dependencies have changed.
     */
    getters: {},

    /*
        As the name suggests it is responsible for mutating the state of the store object, we can easily
        update Vuex state. The exclusive approach to actually update state in a Vuex store is by performing
        a mutation. Vuex mutations are related to events: each mutation has a string type and a handler.
     */
    mutations: {},

    /*
        The action handlers are used to make API calls that is used to perform the CRUD operations to
        manage the data for the Vuex store. Action handlers get a context object which reveals the same
        set of methods and properties on the Vuex store instance.
     */
    actions: {},

    modules: {
        Courses,
        Player
    }
})