import Vue from 'vue'
import VueRouter from 'vue-router'

//Hack to get vue router to jump to correct location if new anchor link is same as current anchor link
//@see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {

    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            window.location = location.path
            return err
        }
        // rethrow error
        return Promise.reject(err)
    })
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),

    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "notfound" */ '@/views/404')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {

        if (to.hash) {
            return {selector: to.hash}
        }

        if (savedPosition) {
            return savedPosition
        }

        return { x: 0, y: 0 }

    }
})

export default router