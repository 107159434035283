const state = {
    loading: false,
    courses: [
        {
            "_id": "60e5714396022d0060f9fa6e-1",
            "name": "Fitness for Life",
            "description": "This course is all about achieving a healthier body and how to reach fitness goals; an ideal entry level course for those who want a career in the health and fitness industry. It covers aspects of health, together with the benefits of exercise and a healthy diet. It also examines how to improve your health and fitness by establishing goals.",
            "image": "/images/fitness.png",
            "playerImage": "/images/fitness-1.jpg",
            "tags": "Fitness",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Health & Exercise",
                    description: "In this episode we take a look at the definitions and factors that affect health and fitness. The many benefits of exercise and the various components of fitness.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=quugo8yTgn0&list=PLkpzWqIgQsc63-EohvGl-kcucfrC1eJSG&index=1",
                    image: "https://img.youtube.com/vi/quugo8yTgn0/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : A Healthy Balanced Diet",
                    description: "This episode highlights the importance of a healthy balanced diet plus we also help you to complete a food diary that will enable you to monitor and control your diet.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=hx3T65wVtls&list=PLkpzWqIgQsc63-EohvGl-kcucfrC1eJSG&index=2",
                    image: "https://img.youtube.com/vi/hx3T65wVtls/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Health and Safety",
                    description: "We show you what contributes to a safe and effective activity routine and we’ll set smart goals for your personal health and fitness, including the importance of personal hygiene.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=bqE7VI_dl7k&list=PLkpzWqIgQsc63-EohvGl-kcucfrC1eJSG&index=3",
                    image: "https://img.youtube.com/vi/bqE7VI_dl7k/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Structured and Self-Directed Exercise",
                    description: "Understand exercise and injury prevention and the principles of training and creating an exercise plan. We also guide you in how to complete your own activity sheet.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=QkS1MZ8aELE&list=PLkpzWqIgQsc63-EohvGl-kcucfrC1eJSG&index=6",
                    image: "https://img.youtube.com/vi/QkS1MZ8aELE/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-1",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-2",
            "name": "Food Hygiene Awareness",
            "description": "In our Food Hygiene Awareness course you will gain a basic understanding of good food hygiene practices, and look at the four C’s: Cross contamination, cleaning, chilling, and cooking.  This course covers all the essential elements of working with food and provides the student with the current best hygiene and food preparation practices.",
            "image": "/images/food.png",
            "playerImage": "/images/food-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-2-1",
                    title: "Episode 1 : Cross Contamination",
                    description: "Part one covers cross contamination, chilling, cooking, and cleaning (the four C’s). Learn about hand washing, effective pest control, illness at work and the process of re-heating food.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=-vcy9xRYucw&list=PLkpzWqIgQsc4XgEeQnEK7HkMvLIp2u_Li&index=1",
                    image: "https://img.youtube.com/vi/-vcy9xRYucw/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-2-2",
                    title: "Episode 2 : Personal Hygiene",
                    description: "This final episode details, all the essentials of personal hygiene when working with food and re-caps the importance of hand washing, how to store food correctly and illness reporting.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=8_lzvBSp2b0&list=PLkpzWqIgQsc4XgEeQnEK7HkMvLIp2u_Li&index=2",
                    image: "https://img.youtube.com/vi/8_lzvBSp2b0/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-2",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-3",
            "name": "Warehousing and Storage",
            "description": "With online retailing growing rapidly the need for well-trained warehouse employees, for companies such as Amazon, has never been greater. This course will give you all the information you need to know about working in a warehouse environment, including health and safety, picking goods, preparing goods for packing, and moving and handling.",
            "image": "/images/warehouse.png",
            "playerImage": "/images/warehouse-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Health & Safety",
                    description: "In this episode we look at all aspects of health and safety including, fire regulations, substance control in a warehouse setting, manual handling, and safe movement of goods.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=hceUdrUFcxU&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=1",
                    image: "https://img.youtube.com/vi/hceUdrUFcxU/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Different Types of Roles in Warehousing",
                    description: "In this episode you will learn and understand the different types of jobs that are available in a warehousing environment and be introduced to equal opportunities, equality, and diversity.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=bNJ7rEoELYI&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=2",
                    image: "https://img.youtube.com/vi/bNJ7rEoELYI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Moving or Handling Goods",
                    description: "We will look at safe working practices and manual handling in the workplace. In this third episode you will be introduced to safe ways of lifting, carrying, pushing, and pulling.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=SrpFBvaEmv4&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=3",
                    image: "https://img.youtube.com/vi/SrpFBvaEmv4/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Picking Goods",
                    description: "In this episode you will be introduced to the safest ways of picking goods in a warehouse setting, learn different methods of picking, the equipment needed and health and safety considerations.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=ii440bmozRM&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=4",
                    image: "https://img.youtube.com/vi/ii440bmozRM/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-5",
                    title: "Episode 5 : Preparing Goods for Packing",
                    description: "In episode five of warehousing and storage you will learn and understand how to prepare goods for packing, including the use of different wrapping & packing materials, methods and equipment.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=DSkaf7SRCvo&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=5",
                    image: "https://img.youtube.com/vi/DSkaf7SRCvo/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-6",
                    title: "Episode 6 : Assembling Goods for Dispatch",
                    description: "In this final episode of warehousing and storage you will learn how to correctly, and safely, move, relocate and stack goods prior to moving them to the dispatch area.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=XuiT2nIf0nw&list=PLkpzWqIgQsc4pi2qKzBrUdPjwAYN4s-v1&index=6",
                    image: "https://img.youtube.com/vi/XuiT2nIf0nw/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-3",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-4",
            "name": "Mind Your Own Business",
            "description": "This course is the perfect introduction to running your own business. Whether you’re looking for inspiration or practical advice - you’ll find it here. The course is broken down into three units which help you understand the skills and knowledge you require to run and market a business and looks at how to manage your finances.",
            "image": "/images/business.png",
            "playerImage": "/images/business-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Have you got what it takes?",
                    description: "Have you got what it takes to be your own boss? Personal financial considerations required – including survival budget tips, business requirements and how to balance business and personal life.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=DRJGdZyn5AE&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=1",
                    image: "https://img.youtube.com/vi/DRJGdZyn5AE/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Knowledge and Skills",
                    description: "Have you got the knowledge and skills required to succeed in business?   Barriers that you may face when starting up a business. What is the best thing about running your own business.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=vlMUMu-8U08&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=2",
                    image: "https://img.youtube.com/vi/vlMUMu-8U08/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Market Research",
                    description: "Market research – What is it? Why is it so important and how do businesses use it? This episode looks at different promotional methods including, traditional media, digital media and social media.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=qG85A99xZv0&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=3",
                    image: "https://img.youtube.com/vi/qG85A99xZv0/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Delivering Products and Services",
                    description: "In this episode we look at how businesses deliver their products and services to the customer, how they achieve positive customer satisfaction and ways in which this can be measured.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=aGJa_esNH2Q&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=4",
                    image: "https://img.youtube.com/vi/aGJa_esNH2Q/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-5",
                    title: "Episode 5 : The Importance of Money",
                    description: "In episode five of Mind Your Own Business, we take a closer look at the importance of money in a business, and how you can plan your business finances.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=dg_LytMBU0s&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=5",
                    image: "https://img.youtube.com/vi/dg_LytMBU0s/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-6",
                    title: "Episode 6 : Business Planning and Cash Flow",
                    description: "In this final episode we look at the documents used to plan and record your business finances, including business plans, cash flow forecast and the importance of good record keeping.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=oX3UBMgOjKM&list=PLkpzWqIgQsc4eCm6bt4VAHpmbUsO5USzv&index=6",
                    image: "https://img.youtube.com/vi/oX3UBMgOjKM/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-4",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-5",
            "name": "Customer Service",
            "description": "This course will give you a great introduction to the knowledge and skills required to work in a customer service role.  Learn why good customer service is important and how to deliver customer satisfaction. Find out how an organisation plans and delivers their customer service, whilst maintaining the required standards of professionalism.",
            "image": "/images/service.png",
            "playerImage": "/images/service-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : The principles of customer service",
                    description: "This first episode looks at the basic Principles of Customer Service and helps you to understand why good customer service is so important to the customer and your business.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=2n2V6-sa0p4&list=PLkpzWqIgQsc7X1dyeyfArPdize4yPqy1G&index=1",
                    image: "https://img.youtube.com/vi/2n2V6-sa0p4/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Planning and Delivery",
                    description: "Episode two in this series explores how organisations’ plan and deliver their customer service, including looking at some of the many different roles within a customer service team.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=HzasfD-g93s&list=PLkpzWqIgQsc7X1dyeyfArPdize4yPqy1G&index=2",
                    image: "https://img.youtube.com/vi/HzasfD-g93s/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Working in customer service",
                    description: "Gain a better understanding of how to work in a customer service role, learn the importance of professionalism and how personal behaviour can positively affect the customer experience.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=IYf4_LYZSs0&list=PLkpzWqIgQsc7X1dyeyfArPdize4yPqy1G&index=3",
                    image: "https://img.youtube.com/vi/IYf4_LYZSs0/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Working in a team",
                    description: "Our final episode will help you to further understand how to work as part of a customer service team, looking at different sources of information and how to use them effectively.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=A0gv5MGZUCI&list=PLkpzWqIgQsc7X1dyeyfArPdize4yPqy1G&index=4",
                    image: "https://img.youtube.com/vi/A0gv5MGZUCI/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-5",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-6",
            "name": "Jobsmart 2.0",
            "description": "JobSmart 2.0 is all about getting candidates ‘interview-ready’ and prepared for the world of work. This course will equip you with the skills and attributes needed to work in today’s modern economy and you will gain a valuable insight into the employability skills needed to succeed in finding and keeping the right job.",
            "image": "/images/jobsmart.png",
            "playerImage": "/images/jobsmart-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Understanding Mindsets",
                    description: "In this first episode we take a closer look at the mindset qualities you will need in order to attract employers and succeed in getting and keeping a job.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=fT58cuUlFjg&list=PLkpzWqIgQsc5NWlVC3DcGUWsmIBaoYZvr&index=1",
                    image: "https://img.youtube.com/vi/fT58cuUlFjg/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Deciding the Right Job for You",
                    description: "This episode looks at finding the right job for you by considering personal and professional strengths and weaknesses and identifies what type of job may suit your current skills and interests.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=3t9YdWx9XCw&list=PLkpzWqIgQsc5NWlVC3DcGUWsmIBaoYZvr&index=2",
                    image: "https://img.youtube.com/vi/3t9YdWx9XCw/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Interview Skills",
                    description: "This programme will give you practical advice on the skills, information and personal presentation techniques required in order to make a good, and lasting first impression at your job interview.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=yQ22PouNAAI&list=PLkpzWqIgQsc5NWlVC3DcGUWsmIBaoYZvr&index=3",
                    image: "https://img.youtube.com/vi/yQ22PouNAAI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Problem Solving in The Workplace",
                    description: "This episode gives practical advice on how to deal with issues arising in the workplace and where you could obtain the help and advice needed to deal with these issues.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=ylLfifyPXb4&list=PLkpzWqIgQsc5NWlVC3DcGUWsmIBaoYZvr&index=4",
                    image: "https://img.youtube.com/vi/ylLfifyPXb4/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-6",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-7",
            "name": "Creative Writing",
            "description": "For all you budding novelists this Way2Learn course will help you to understand how to compose your own piece of creative writing. During this course you will discover how to become inspired and obtain ideas, explore how to originate engaging characters, and learn the techniques used to create interesting settings for your story.",
            "image": "/images/writing.png",
            "playerImage": "/images/writing-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Sources of Inspiration",
                    description: "The first episode explores some of the requirements needed to become a writer.  Phil Earle takes you step by step through the writing process and looks at potential sources of inspiration.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=4UGQBPy7Sbc&list=PLWHQmhAfZbwyaUPpNe66I044_0R6gqc4G&index=1&t=44s",
                    image: "https://img.youtube.com/vi/4UGQBPy7Sbc/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Creating Characters",
                    description: "All great books have one thing in common, they all contain engaging and realistic characters.  In this episode Phil Earle takes a look at how to create characters and settings.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=k7SB90mFH3I&list=PLWHQmhAfZbwyaUPpNe66I044_0R6gqc4G&index=2",
                    image: "https://img.youtube.com/vi/k7SB90mFH3I/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Creating Plots and Dialogue",
                    description: "In our final episode in the creative writing series you will discover how to give your characters a plot and a voice by creating effective plots and dialogue.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=JJDpkC0OsiU&list=PLWHQmhAfZbwyaUPpNe66I044_0R6gqc4G&index=3",
                    image: "https://img.youtube.com/vi/JJDpkC0OsiU/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-7",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-8",
            "name": "Minute Maths",
            "description": "This popular course is all about making maths accessible and teaching some important core skills. This course contains multi-level maths problems to test your knowledge and skill. It is set out over twelve sections covering the area, volume, and perimeter of different shapes, calculating averages and percentages, and everybody’s favourite – fractions.",
            "image": "/images/math.png",
            "playerImage": "/images/math-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Area",
                    description: "Whether you are laying turf in your garden, or decorating and awkwardly shaped room in this episode Ryan will help you with calculating the area of regular and irregular shapes.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=COzsO2XqLL8&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=1",
                    image: "https://img.youtube.com/vi/COzsO2XqLL8/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Perimeter",
                    description: "In this episode of minute maths, we take a look at how you can successfully calculate the perimeter of regular shapes and also the more problematic, irregular shapes.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=AQNzqEes0UA&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=2",
                    image: "https://img.youtube.com/vi/AQNzqEes0UA/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Mean",
                    description: "In this third episode of minute maths your teacher, Ryan, explains what the mean average is and explains in clear and simple terms how you can calculate it.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=ZSf-SSldiQM&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=3",
                    image: "https://img.youtube.com/vi/ZSf-SSldiQM/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Median",
                    description: "In this fourth episode of minute maths your teacher, Ryan, explains in clear and simple terms how to find the median of a set of numbers.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=AKg4zuTm96M&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=4",
                    image: "https://img.youtube.com/vi/AKg4zuTm96M/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-5",
                    title: "Episode 5 : Mode",
                    description: "In this fifth episode of minute maths your teacher, Ryan, explains in clear and simple terms the meaning of the mode and how to find it.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=6P0udslm9f8&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=5",
                    image: "https://img.youtube.com/vi/6P0udslm9f8/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-6",
                    title: "Episode 6 : Range",
                    description: "In this sixth episode of minute maths your teacher, Ryan, explains in clear and simple terms how to calculate the range of a group of numbers.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=BaK-aXAgp00&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=6",
                    image: "https://img.youtube.com/vi/BaK-aXAgp00/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-7",
                    title: "Episode 7 : Volume",
                    description: "In this seventh episode of minute maths your teacher, Ryan, explains in clear and simple terms how to calculate the volume of 3D shapes.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=gTqjhVLXJao&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=7",
                    image: "https://img.youtube.com/vi/gTqjhVLXJao/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-8",
                    title: "Episode 8 : Fractions",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how to work out fractions in the context of working out money.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=9hejrZKYdfw&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=8",
                    image: "https://img.youtube.com/vi/9hejrZKYdfw/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-9",
                    title: "Episode 9 : More Fractions",
                    description: "This is the second part of working out fractions.  In this episode your teacher, Ryan, explains in clear and simple terms how to calculate fractions.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=B1WhYgyDyDw&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=9",
                    image: "https://img.youtube.com/vi/B1WhYgyDyDw/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-10",
                    title: "Episode 10 : Percentages",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how you can multiply a number by a percentage.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=enRTc-dfqCI&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=10&t=120s",
                    image: "https://img.youtube.com/vi/enRTc-dfqCI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-11",
                    title: "Episode 11 : More Percentages",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how you can express an amount in a percentage of a number.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=m9Mam203llM&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=11",
                    image: "https://img.youtube.com/vi/m9Mam203llM/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-12",
                    title: "Episode 12 : Area of a Triangle",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how to calculate the area of a triangle.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=5HBQRmr20JI&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=12",
                    image: "https://img.youtube.com/vi/5HBQRmr20JI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-14",
                    title: "Episode 14 : Cubing a Number",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how to calculate the cube of a number.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=ArfQUbv324Y&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=14",
                    image: "https://img.youtube.com/vi/ArfQUbv324Y/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-15",
                    title: "Episode 15 : Area of a Circle",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms how to Calculate the area of a circle.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=QczQ-7iMuLI&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=15",
                    image: "https://img.youtube.com/vi/QczQ-7iMuLI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-17",
                    title: "Episode 17 : Ratio",
                    description: "In this latest episode of minute maths your teacher, Ryan, explains in clear and simple terms the techniques needed to work out ratio.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=Qu5bcUiM7JE&list=PLWHQmhAfZbwygRH_49KOtG53F-m1hrqNL&index=16",
                    image: "https://img.youtube.com/vi/Qu5bcUiM7JE/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-8",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-9",
            "name": "Creative Design",
            "description": "This course is perfect for those who want to work in graphic design, have an interest in this field or simply want to design their own logo. As well as covering aspects of logo design, the course explores different roles within the industry.  If you want to create a logo for your business this is the course for you.",
            "image": "/images/design.png",
            "playerImage": "/images/design-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Working Safely in Design",
                    description: "You will be introduced to the role of a graphic designer.  This episode will introduce you to the health and safety considerations you will need to adhere to when working in a studio.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=Y7FCHPBsdNM&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=1&t=626s",
                    image: "https://img.youtube.com/vi/Y7FCHPBsdNM/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Jobs in Design",
                    description: "You’ll understand the different assignments a designer takes on – from print and mobile app design, through to signage and logo design. Plus, we look at the different stages of a creative project.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=CUPZTZbsLr0&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=2",
                    image: "https://img.youtube.com/vi/CUPZTZbsLr0/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Jobs in Design Pt2",
                    description: "We’ll look at the responsibilities of a freelance graphic designer and the skills required. Topics covered include finding work, billing clients and dealing with those ‘creative blocks’.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=Aut_qRSQSSg&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=3",
                    image: "https://img.youtube.com/vi/Aut_qRSQSSg/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : Skills in Design",
                    description: "In this episode we look at the drawing tutorials, tips, and techniques, to help you visualise your ideas and develop striking logo designs. You’ll also find sample design briefs to practice with.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=AsFOuSTulfo&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=4",
                    image: "https://img.youtube.com/vi/AsFOuSTulfo/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-5",
                    title: "Episode 5 : Research, Plan & Design",
                    description: "This episode looks at designing your final logo. In this module, we’ll develop your brief and consider what aspects of your logo will make your design a success.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=WblbBv_bt5U&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=5",
                    image: "https://img.youtube.com/vi/WblbBv_bt5U/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-6",
                    title: "Episode 6 : Design and Evaluation",
                    description: "It’s a well-known fact that reviewing your work will help improve your design skills and creative process. This analysis is a key exercise for designers working in the industry.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=lTQiQf36WZs&list=PLkpzWqIgQsc5iwee1k7YzSlLJ1kf1hPfS&index=6",
                    image: "https://img.youtube.com/vi/lTQiQf36WZs/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-9",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-10",
            "name": "Principles of Journalism",
            "description": "This course is a great introduction to the principles of journalism. The course looks at the key fundamentals of journalism. It will help you to gain an understanding of what it takes to be a great journalist and the techniques used for writing for a newspaper.  You will also gain an understanding of the principles of gathering and telling a story.",
            "image": "/images/journalism.png",
            "playerImage": "/images/journalism-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Gathering a Story",
                    description: "In this first episode we will explain the principles of gathering a story, as well as looking at the principles of telling a balanced and engaging story.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=e2Nz9VGQuQI&list=PLkpzWqIgQsc5fm32u_5rEsR7XN6fDDIXt&index=1&t=37s",
                    image: "https://img.youtube.com/vi/e2Nz9VGQuQI/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Building a News Story",
                    description: "In the second episode you will learn the final principles of journalism, how to identify sources of information and how to successfully build a balanced and engaging news story.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=0NrGry_p2YA&list=PLkpzWqIgQsc5fm32u_5rEsR7XN6fDDIXt&index=2",
                    image: "https://img.youtube.com/vi/0NrGry_p2YA/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : How to Lead an Interview",
                    description: "In this final episode you will be shown how to lead an interview, how to get a job in journalism and you will demonstrate your own ability to write a story.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=nsRQFXj37ec&list=PLkpzWqIgQsc5fm32u_5rEsR7XN6fDDIXt&index=3",
                    image: "https://img.youtube.com/vi/nsRQFXj37ec/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-10",
        },
        {
            "_id": "60e5714396022d0060f9fa6e-11",
            "name": "Workplace Digital Skills",
            "description": "The Workplace Digital Skills course is designed to introduce you to the digital world, to help you in your search for work.  The six units in this course will take you on a journey from how to search effectively online for jobs, through to applying for jobs by email and using social media to develop your career prospects.",
            "image": "/images/digital.png",
            "playerImage": "/images/digital-1.jpg",
            "resources": [
                {
                    _id: "60e5714396022d0060f9fa6e-1-1",
                    title: "Episode 1 : Using and Managing Information",
                    description: "Find smarter ways to search, identify and evaluate information effectively to meet personal and organisational needs. Ensuring that the information that is obtained is reliable and from creditable sources.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=u_941CffATY&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=1&t=127s",
                    image: "https://img.youtube.com/vi/u_941CffATY/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-2",
                    title: "Episode 2 : Trusting and Organising Information",
                    description: "What websites are real or fake? Applying a simple test to decide if you can trust the information. Looking at ways to organise your information so that it is easy to find and identify.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=dYJhYlXHENQ&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=2",
                    image: "https://img.youtube.com/vi/dYJhYlXHENQ/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-3",
                    title: "Episode 3 : Keeping Safe Online",
                    description: "In this episode you will learn the techniques needed to prevent yourself and others from being attacked by nasty viruses and bugs, which can compromise your data, devices and systems.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=kFFDrpuKY84&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=3",
                    image: "https://img.youtube.com/vi/kFFDrpuKY84/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-4",
                    title: "Episode 4 : The Law and Your Data",
                    description: "This episode explains the rules for businesses in relation to storing people’s data, and the personal and legal implications if data is not processed fairly or securely.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=TI7r1sbl9s8&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=4",
                    image: "https://img.youtube.com/vi/TI7r1sbl9s8/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-5",
                    title: "Episode 5 : Communicating Online",
                    description: "Think before you tweet! The online equivalent of not revealing too much information about yourself and minding your P’s and Q’s. Learn what is acceptable behaviour online and what isn’t? ",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=UHPG3KL1ln4&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=5",
                    image: "https://img.youtube.com/vi/UHPG3KL1ln4/hqdefault.jpg"
                },
                {
                    _id: "60e5714396022d0060f9fa6e-1-6",
                    title: "Episode 6 : Digital Career Development",
                    description: "This episode looks at the art of selling you and your business online. Ways in which to promote yourself and your business to maximise profits and customers and to snare that dream job.",
                    aspectRatio: "16:9",
                    uri: "https://www.youtube.com/watch?v=5NlF-N7iOkI&list=PLWHQmhAfZbwxyKTvAQRtR9VD1fS1PwXiv&index=6",
                    image: "https://img.youtube.com/vi/5NlF-N7iOkI/hqdefault.jpg"
                },
            ],
            "uuid": "bf54715b-cf9d-4e90-beff-93874330c5e7-11",
        },
    ]
}
const getters = {
    loading: state => {
        return state.loading
    },
    courses: state => {
        return state.courses
    },
}
const actions = {}
const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}