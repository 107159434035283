import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                a: '#b11116',
                primary: '#3eacf5',
                secondary: '#348ccf',
                accent: '#535353',
                error: '#ff0033',
            },
            dark: {
                a: '#000000',
                primary: '#3EC4F5',
                secondary: '#348ccf',
                accent: '#535353',
                error: '#ff0033',
            }
        }

    }
});
