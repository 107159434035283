const state = {
    playing: false,
    video: null,
}
const getters = {
    playing: state => {
        return state.playing
    },
    video: state => {
        return state.video
    },
}
const actions = {
    doPlayVideo(context, video) {
        context.commit('playVideo', video);
        window.scrollTo(0, 0);
    },
    doStopVideo(context) {
        context.commit('stopVideo');
        window.scrollTo(0, 0);
    }
}
const mutations = {
    playVideo(state, video){
        state.video = video
        state.playing = true
    },
    stopVideo(state){
        //state.video = null
        state.playing = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}