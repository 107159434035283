<template>
  <v-app >
    <v-main class="bg">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style scoped>
.bg {
  background-image: linear-gradient(315deg, #252424 0%, #1d1b1b 100%);
  height: 100%;
}
</style>
